import React, { FunctionComponent } from "react";
import "./styles.scss";

interface SloganTeaserInterface {
    heading?: string;
    bgColor?: any;
}

const SloganTeaser: FunctionComponent<SloganTeaserInterface> = ({ heading, bgColor }) => {
    return (
        <section className={`rx-slogan-teaser rx-theme-${(bgColor && bgColor.title) ? bgColor.title.replaceAll(' ', '').toLowerCase() : 'default'}`} style={{ backgroundColor: `${bgColor.value}` }} >
            <h3 className="rx-slogan-teaser--title">{heading}</h3>
        </section>
    );
};

export default SloganTeaser;
